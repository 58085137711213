@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-500 dark:text-gray-400 text-sm bg-gray-100 dark:bg-gray-900 leading-normal font-sans;
    -webkit-font-smoothing: antialiased;
    font-family: "Gilroy-Regular", sans-serif;
  }

  hr {
    @apply border-gray-200 dark:border-gray-600;
  }

  .heading-text {
    /* font-family: 'Gilroy-Regular', sans-serif; */
    @apply text-gray-900 dark:text-gray-100;
  }

  p {
    font-family: "Gilroy-Regular", sans-serif;
    @apply font-thin;
  }
}

@font-face {
  font-family: "Gilroy-Black", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Black", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Black.eot");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-BlackItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-Bold", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-BoldItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-ExtraBold", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-ExtraBoldItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bolder;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-SemiBold", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-Light", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-LightItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-LightItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-Medium", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-MediumItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: medium;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-Regular", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Regular", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Regular.eot");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Regular", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Regular.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Thin", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Thin.ttf") format("truetype");
  font-weight: lighter;
  font-style: light;
}
@font-face {
  font-family: "Gilroy-ThinItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-Heavy", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-Heavy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-HeavyItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-UltraLightItalic", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy-UltraLight", sans-serif;
  src: url("/src/assets/gilroy/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
.modal-open {
  background-color: #ffffff; /* Replace with the desired gray-100 color code */
}
/* Add this to your CSS file or style section */
.background-white {
  background-color: #ffffff;
}

.tabo {
  position: relative;
  cursor: pointer;
}
.tabo::after {
  content: "";
  position: absolute;
  cursor: pointer;
  width: 0.175rem;
  height: 70%;
  background: #34d399;
  left: 95px;
  bottom: 0;
  top: 4px;
}
@keyframes loaderAnimation {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.animate-loader {
  animation: loaderAnimation 3s linear infinite;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}
.custom-row-class {
  padding-top: 10px;
  padding-bottom: 10px;
}
